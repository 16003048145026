import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";
import { DefaultGtag } from '../utils/misc'
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import '../styles/Contact.css'


const mainStyles = {
    display: "flex",
    flexDirection: "column",
    background: "rgb(250, 250, 250)",
    paddingTop: "7rem"
}

const pageStyle = {
    width: "60rem",
    margin: "auto",
    padding: "1rem 2rem",
    marginBottom: "3rem",
    maxWidth: "100%"
    // maxWidth: "calc(100% - 4rem)"
}

const AboutPage = () => {
    const { t } = useTranslation();

    const [business, setBusiness] = useState("menu")
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [desc, setDesc] = useState("")

    useEffect(() => {
        setTimeout(function () {
            // need delay, otherwise not working on firefox...
            window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        }, 100);
    }, [])

    return (
        <Layout>
            <main style={mainStyles}>
                <div className="Contact homepage" style={pageStyle}>
                    <h1 className="title">{t('contact.title')}</h1>
                    <div className="desc">{t('contact.desc')}</div>
                    <div className="pages">
                        <div className="contact-info">
                            <div className="title">{t('contact.sub.title')}</div>
                            <div className="desc"> {t('contact.sub.desc')} </div>
                            <div className="fields">
                                <div><EmailIcon /> contact@takeaseat.io</div>
                                <div><LocationOnIcon /> Zürich, {t('contact.sub.ch')}</div>
                            </div>
                        </div>
                        <div className="contact-form">
                            <div>{t('contact.sub.int')}</div>
                            <div className="fields">
                                <div className={business === "menu" ? "selected" : ""} onClick={() => setBusiness("menu")}>{t('contact.sub.sol.0')}</div>
                                <div className={business === "website" ? "selected" : ""} onClick={() => setBusiness("website")}>{t('contact.sub.sol.1')}</div>
                                <div className={business === "table-reservations" ? "selected" : ""} onClick={() => setBusiness("table-reservations")}>{t('contact.sub.sol.2')}</div>
                                <div className={business === "contact-tracing" ? "selected" : ""} onClick={() => setBusiness("contact-tracing")}>{t('contact.sub.sol.3')}</div>
                                <div className={business === "other" ? "selected" : ""} onClick={() => setBusiness("other")}>{t('contact.sub.sol.4')}</div>
                            </div>
                            <div className="details">
                                <input placeholder={t('contact.sub.fields.0')} onChange={(e) => setName(e.target.value)} value={name} />
                                <input placeholder={t('contact.sub.fields.1')} onChange={(e) => setEmail(e.target.value)} value={email} />
                                <input placeholder={t('contact.sub.fields.2')} onChange={(e) => setDesc(e.target.value)} value={desc} />
                                <div className="tas-button" onClick={() => {
                                    if (!sending && !success) {
                                        setSending(true)
                                        fetch(`https://api.takeaseat.io/promo/apply`, {
                                            method: 'post',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json'
                                            },
                                            credentials: "include",
                                            body: JSON.stringify({ name, email, business, desc, address: "no address" })
                                        }).then(resp => {
                                            setSending(false)
                                            if (resp.status === 200) {
                                                setSuccess(true)
                                                setError(false)
                                                setName("")
                                                setBusiness("menu")
                                                setEmail("")
                                                setDesc("")
                                            }
                                            if (resp.status === 400) resp.json().then(txt => setError(txt))
                                        })
                                    }
                                }}>
                                    {sending && <span style={{ position: "absolute", left: "50%", transform: "translate(-50%, 0%)" }}><ScheduleSendIcon /></span>}
                                    {success && <span style={{ position: "absolute", left: "50%", transform: "translate(-50%, 0%)" }}><DoneIcon /></span>}
                                    {error && <span style={{ position: "absolute", left: "50%", transform: "translate(-50%, 0%)" }}><CloseIcon /></span>}
                                    <span style={(sending || success || error) ? { visibility: "hidden" } : {}}>{t('contact.sub.send')}</span>
                                </div>
                                <div style={{ marginLeft: "auto" }}>{success && <>{t('contact.sub.sent')}</>}</div>
                                <div style={{ marginLeft: "auto" }}>{error && <>{error}</>}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default AboutPage

export const Head = () => <>
    <DefaultGtag />
    {/* <link rel="canonical" href="https://takeaseat.io/contact/" /> */}
    <link rel="alternate" hreflang="x-default" href="https://takeaseat.io/contact/" />
    <link rel="alternate" hreflang="fr" href="https://takeaseat.io/fr/contact/" />
    <link rel="alternate" hreflang="de" href="https://takeaseat.io/de/contact/" />
    <link rel="alternate" hreflang="es" href="https://takeaseat.io/es/contact/" />
    <title>Contact Take a Seat</title>
    <meta name="description" content="Online solutions for restaurants and bars. Website builder, QR menus, table reservations and more." />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Contact" />
    <meta property="og:description" content="Online solutions for restaurants and bars. Website builder, QR menus, table reservations and more." />
    <meta property="og:image" content="https://takeaseat.io/static/media/images/all-in-one-6" />
    <meta property="og:url" content="https://takeaseat.io/contact/" />
    <meta property="og:site_name" content="Take a Seat" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content="Contact Take a Seat" />
    <meta name="twitter:description" content="Online solutions for restaurants and bars. Website builder, QR menus, table reservations and more." />
    <meta name="twitter:image" content="https://pbs.twimg.com/media/FEjpZgbXIAoTpq4?format=jpg&name=4096x4096" />
    <meta name="twitter:site" content="@Takeaseat_io" />
    <meta name="twitter:creator" content="@Takeaseat_io" />
</>

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["cookies","contact","navigation"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;